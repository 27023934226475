/**
 * Map
 * @description : Google Map centred on coordinates with custom marker
 */

window.mapStyles = [
    {
        "featureType": "all",
        "elementType": "all",
        "stylers": [
            {
                "saturation": "-100"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "lightness": "-62"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "lightness": "100"
            },
            {
                "gamma": "10.00"
            },
            {
                "saturation": "-100"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "lightness": "70"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [
            {
                "lightness": "50"
            }
        ]
    },
    {
        "featureType": "poi.attraction",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.attraction",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi.business",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.government",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "poi.medical",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.place_of_worship",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.school",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.sports_complex",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "lightness": "-100"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "weight": "0.1"
            },
            {
                "lightness": "-100"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "weight": "1"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway.controlled_access",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "weight": "0.60"
            },
            {
                "lightness": "26"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "weight": "0.38"
            },
            {
                "saturation": "0"
            },
            {
                "lightness": "75"
            },
            {
                "gamma": "0.92"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "weight": "1"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit.line",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "transit.station",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "saturation": "-100"
            },
            {
                "lightness": "71"
            }
        ]
    }
];

(function () {

    var mapEls = document.querySelectorAll('.js-map-multi');
    var locations = document.querySelectorAll('.js-location');
    var markerData = [];

    locations.forEach(location => {

        markerData.push({
            "location": location.getAttribute('data-location-name'),
            "coordinates": location.getAttribute('data-coordinates'),
            "imageUrl": location.querySelector('.js-location-image') ? location.querySelector('.js-location-image').getAttribute('data-image') : '',
            "link": location.getAttribute('data-url'),
        })

    })

    window.initMultiTabMaps = function () {
        mapEls.forEach(function (mapEl) {
            var coordinatesString = markerData[0].coordinates;
            if (!coordinatesString || coordinatesString == " ") {
                coordinatesString = "-36.84290403323459 174.76635474818485";
            }

            var coordinates = coordinatesString.trim().split(/\s+/).map(function (val) { return parseFloat(val); });
            if (coordinates.length !== 2) {
                console.warn('Map element (.js-map) has no space-separated lat/long coordinates. Coordinates value was "' + coordinatesString + '".');
            }

            var latLng = { lat: coordinates[0], lng: coordinates[1] };
            var zoomLevel = parseInt(mapEl.getAttribute('data-zoom-level')) || 14;

            var map = new google.maps.Map(mapEl, {
                center: latLng,
                zoom: zoomLevel,
                styles: mapStyles || []
            });

            markerData.forEach((marker, index) => {
                var coordinatesString = marker.coordinates;
                if (!coordinatesString) {
                    console.warn('Map element (.js-location) has no data-coordinates defined.');
                    return;
                }

                var coordinates = coordinatesString.trim().split(/\s+/).map(function (val) { return parseFloat(val); });
                if (coordinates.length !== 2) {
                    console.warn('Map element (.js-map) has no space-separated lat/long coordinates. Coordinates value was "' + coordinatesString + '".');
                }

                var latLng = { lat: coordinates[0], lng: coordinates[1] };
                var pinTitle = marker.location;
                
                if (index > 10) {
                    index = String(index).split("")[String(index).split("").length - 1];
                }

                var icon = {
                    url: '/images/map-markers/map-marker-' + index + '.svg',
                    scaledSize: new google.maps.Size(40, 55),
                }

                const contentString = `
                    <div class="c-google-map__info-box">
                        <a href="` + marker.link + `">
                            <div class="c-info-box__content">
                                <img
                                    src="` + marker.imageUrl + `" 
                                    alt="` + marker.location + `"
                                    onerror="this.style.background='rgba(0, 0, 0, 0.9'"
                                />
                                <header class="c-tile__header">
                                    <h3 class="c-tile__heading">` + marker.location + `</h3>
                                </header>
                            </div>
                            <div class="c-tile__toolbar  c-toolbar">
                                <span class="c-toolbar__cta">View more</span>
                            </div>
                        </a>
                    </div>
                `;

                const infowindow = new google.maps.InfoWindow({
                    content: contentString,
                });

                var mark = new google.maps.Marker({
                    position: latLng,
                    map,
                    title: pinTitle,
                    icon: icon
                });

                mark.addListener("click", () => {
                    infowindow.open({
                        anchor: mark,
                        map,
                        shouldFocus: false,
                    });
                })

            });

        });
    };

})();